<template>
  <div class="stationView">
    <main class="player-content flex-row">
      <section class="flex-1">
        <div class="flex-autorow flex-top flex-stretch player-channel">
          <!-- station details -->

<span> Essai </span>

        </div>

        <!--<syncLyrics></syncLyrics>-->

      </section>
    </main>

  </div>
</template>

<script>



export default {
  name: 'station',
  data: () => {
    return {
      // toggles
      visible: false,
      playing: false,
      loading: false,
      volume: 1,
      // errors stuff
      errors: {},
      // timer stuff
      timeStart: 0,
      timeDisplay: '00:00:00',
      timeItv: null,

    }
  },





}
</script>
